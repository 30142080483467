<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input v-model="selectDate.projectCode" placeholder="请输入班级编号" size="small"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" clearable />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input v-model="selectDate.projectName" placeholder="请输入班级名称" size="small" clearable />
            </div>
            <div title="开班单位" class="searchboxItem ci-full">
              <span class="itemLabel">开班单位:</span>
              <el-input v-model="selectDate.compName" placeholder="请输入开班单位" size="small" clearable />
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader v-model="selectDate.areaId" :options="areatreeList" :props="propsarea" clearable filterable
                size="small"></el-cascader>
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">状态:</span>
              <el-select v-model="selectDate.projectStudyTimeState" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in classtatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <tree ref="tree" @eventBtn="childBack" :ruleForm="ruleForm" size="small" @clearParams="clearParams"
                typeStu />
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="是否暂停" class="searchboxItem ci-full">
              <span class="itemLabel">是否暂停:</span>
              <el-select v-model="selectDate.isPause" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in isPauseList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="补贴班级" class="searchboxItem ci-full">
              <span class="itemLabel">补贴班级:</span>
              <el-select v-model="selectDate.isSubsidy" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in SubsidyList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker clearable size="small" v-model="selectDate.creationTime" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
          </div>
          <div class="searchbox">
            <div title="开始时间" class="searchboxItem ci-full">
              <span class="itemLabel">开始时间:</span>
              <el-date-picker clearable size="small" v-model="selectDate.startTime" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="结束时间" class="searchboxItem ci-full">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker clearable size="small" v-model="selectDate.endTime" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="200px"
                fixed />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" width="200" />
              <el-table-column label="开班单位" align="left" show-overflow-tooltip prop="compName" width="200" />
              <el-table-column label="要求学时" align="right" show-overflow-tooltip prop="lessonNum" />
              <el-table-column label="备案课时数" align="right" show-overflow-tooltip prop="recordClasshours">
                <template slot-scope="scope">
                  {{ scope.row.recordClasshours || "--" }}
                </template>
              </el-table-column>
              <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaName" />
              <el-table-column label="负责人" align="left" show-overflow-tooltip prop="projectUser" />
              <el-table-column label="开始时间" align="left" show-overflow-tooltip prop="startDate" minWidth="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.startDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="结束时间" align="left" show-overflow-tooltip prop="endDate" minWidth="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.endDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="报名截止" align="left" show-overflow-tooltip prop="signEndDate" minWidth="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.signEndDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" show-overflow-tooltip prop="projectStudyTimeState">
                <template slot-scope="scope">
                  <span>{{
                $setDictionary(
                  "PROJECTSTATE",
                  scope.row.projectStudyTimeState
                )
              }}</span>
                </template>
              </el-table-column>
              <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" minWidth="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="trainTypeNamePath" width="150" />
              <el-table-column label="是否暂停" align="left" show-overflow-tooltip prop="isPause" width="150">
                <template slot-scope="scope">
                  {{ scope.row.isPause ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="是否补贴班级" align="left" show-overflow-tooltip prop="isSubsidy" width="150">
                <template slot-scope="scope">
                  {{ scope.row.isSubsidy ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="是否允许打印学时证明（或档案）" align="center" show-overflow-tooltip prop="allowPrint"
                width="120px" fixed="right">
                <template slot-scope="scope">
                  <el-switch class="selStyle" v-model="scope.row.allowPrint" width="40"
                    @change="getSwitch(scope.row.projectId)"></el-switch>
                  <span style="margin-left: 4px">是</span>
                </template>
              </el-table-column>
              <el-table-column label="是否允许学员查看证书" align="center" show-overflow-tooltip prop="studentCertOperatePrint"
                width="120px" fixed="right">
                <template slot-scope="scope">
                  <el-switch class="selStyle" v-model="scope.row.studentCertOperatePrint" width="40"
                    @change="getStudentCertOperatePrint(scope.row.projectId)"></el-switch>
                  <span style="margin-left: 4px">是</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="left" width="260px" fixed="right">
                <div slot-scope="scope">
                  <el-button style="padding: 0 5px" type="text" size="mini"
                    @click="seeDetails(scope.row)">详情</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="
                openStartClass(
                  scope.row)
                ">开课记录</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="
                studentInfo(
                  scope.row.projectId,
                  scope.row.projectSource,
                  scope.row.auditState,
                  scope.row.projectName
                )
                ">学员信息</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="configurationQuestionBank(scope.row)">配置题库</el-button>
                  <el-button v-if="scope.row.isGSYF" type="text" size="mini" style="padding: 0 5px"
                    @click="qrCodeIsGenerated(scope.row.projectId, scope.row.projectName)">查看进度二维码</el-button>

                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="fileInvalidation(scope.row.projectId)">档案失效</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="SummaryFileInvalidation(scope.row.projectId)">汇总档案失效</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" :disabled="!scope.row.enableConfigParam"
                    @click="Classparameters(scope.row.projectId, scope.row.projectStudyTimeState)">班级参数</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" :disabled="scope.row.projectSource != '10'"
                    @click="RemoveNonCertified(scope.row.projectId)">清除未认证学员</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="AutomaticEvaluation(scope.row.projectId)">自动评价</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="pauseStudy(scope.row)">暂停学习设置</el-button>
                  <el-button type="text" size="mini" :disabled="scope.row.auditState != '30'" style="padding: 0 5px"
                    @click="checkMaintenance(scope.row)">班级抽查维护</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
        <courseQuestionBank ref="courseQuestionBank" @eventBus="courseTableMoreBack"></courseQuestionBank>
      </div>
    </div>
    <!-- 暂停学习设置 -->
    <el-dialog title="暂停学习设置" :visible.sync="dialogVisible" width="1200px" top="3%" :center="true"
      :before-close="doClose" :close-on-click-modal="false">
      <div class="ovy-a">
        <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
          <el-form-item label="暂停设置：" prop="isPause">
            <el-radio-group v-model="Form.isPause" @change="clearPauseList">
              <el-radio :label="false">恢复学习</el-radio>
              <el-radio :label="true">暂停学习</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="不可学习时间：" prop="studyTime" v-if="Form.isPause">
            <el-row :key="index" v-for="(item, index) in Form.pauseDateList">
              <el-col :span="24">
                <el-col :span="7">
                  <el-date-picker type="daterange" v-model="item.pauseDate" :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd" format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" :clearable="false" @change="checkDaterange($event, index)">
                  </el-date-picker>
                </el-col>
                <el-col :span="1"> &nbsp; </el-col>
                <el-col :span="10">
                  <el-row style="margin-bottom: 16px" :key="indexInner"
                    v-for="(itemInner, indexInner) in item.pauseTimeList">
                    <el-col :span="12">
                      <el-time-picker is-range v-model="itemInner.pauseTime" range-separator="~"
                        start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" format="HH:mm:ss"
                        :clearable="false">
                      </el-time-picker>
                    </el-col>
                    <el-col :span="1"> &nbsp; </el-col>
                    <el-col :span="11">
                      <el-button type="primary" plain round size="small" v-if="indexInner == 0"
                        @click="puseAddTime(index)">添加时间</el-button>
                      <el-button type="danger" plain round size="small" v-if="item.pauseTimeList.length > 1"
                        @click="puseDeletetime(index, indexInner)">删除时间</el-button>
                      &nbsp;
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="3">
                  <el-button type="danger" round size="small" @click="puseDeleteDate(index)">删除日期</el-button>
                </el-col>
              </el-col>
            </el-row>
            <el-button type="primary" round size="small" @click="puseAddDate">添加日期</el-button>

            <p style="color: red">温馨提示：若未选择日期则默认立即暂停学习！</p>
          </el-form-item>

          <!--          <el-form-item-->
          <!--            label="不可学习时间"-->
          <!--            prop="studyTime"-->
          <!--            v-if="Form.isPause"-->
          <!--          >-->
          <!--            <el-date-picker-->
          <!--              type="dates"-->
          <!--              :picker-options="pickerOptions"-->
          <!--              v-model="Form.studyTime"-->
          <!--              value-format="yyyy-MM-dd"-->
          <!--              format="yyyy-MM-dd"-->
          <!--              placeholder="选择一个或多个日期"-->
          <!--              @change="dateChange"-->

          <!--            >-->
          <!--            </el-date-picker>-->
          <!--            <p style="color:red">温馨提示：若未选择日期则默认立即暂停学习！</p>-->
          <!--          </el-form-item>-->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <el-button class="bgc-bv" @click="doClose('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
        <el-button class="bgc-bv" @click="exportRecords">导出</el-button>
      </span>
    </el-dialog>
    <!-- 生成二维码 -->
    <el-dialog width="800px" :title="innerTitle" modal="false" :visible.sync="innerVisible"
      @close="innerVisible = false" :close-on-click-modal="false">
      <div>
        <div style="display: flex">
          <div class="leftData" style="text-align:center">
            <span style="color: tomato; font-size: 1.25rem">{{
                projectNames
              }}</span>
            <img :src="qrcode" alt />
            <el-button class="bgc-bv" @click="qrCodeIsGenerated(innerProjectId, innerTitle, true)">重新生成二维码</el-button>
          </div>
          <div class="dailyRemind">
            <div class="dailyRemind-1">
              <h2>企业查看学员学习进度</h2>
              <p>1.通过微信＂扫一扫＂扫描二维码；</p>
              <p>2.关注＂安知平台＂公众号；</p>
              <p>3.点击【点击绑定】；</p>
              <p>4.输入完整的企业名称＼手机号＼身份证号＼姓名来绑定班级信息；</p>
              <p>5.绑定成功后即可查看企业学员的学习进度。</p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import courseQuestionBank from "@/views/classListCom/classDetailsFile/courseQuestionBank";
import moment from "moment";
import QRCode from "qrcodejs2"; // 引入qrcode
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classListCtrY",
  components: {
    Empty,
    PageNum,
    tree,
    courseQuestionBank,
  },
  mixins: [List],
  data() {
    return {
      selectDate: {
        projectName: "", //班级名称
        projectCode: "", //班级编码
        compName: "", //开班单位
        areaId: "", //行政区域
        projectStudyTimeState: "", //状态
        creationTime: "", //创建时间
        startTime: "", //开始时间
        endTime: "", //结束时间
        isSubsidy: "", //班级补贴
      },
      areatreeList: [],
      classtatusList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },

      docsForm: {},
      selectedItem: {},
      docsOptions: {
        10: "学时证明",
        20: "学员培训档案",
      },
      ruleForm: {
        Trainingtype: "",
      },
      params: {},
      isPauseList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      SubsidyList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],

      dialogVisible: false, //暂停学习弹窗
      //   暂停学习表单数据
      Form: {
        isPause: "",
        pauseDateList: [],
      },
      rules: {
        isPause: [
          { required: true, trigger: "change", message: "请选择暂停设置" },
        ],
      },

      oldData: [], //回显总数据
      cancelPauseIdList: [], //取消时间的PauseId
      fstartDate: "", //开班时间
      fendDate: "", //结束时间
      // 生成二维码
      innerTitle: '',
      innerVisible: false,
      qrcode: '',
      innerProjectId: '',
      // 暂停集合
      // pauseDateList: [],
    };
  },
  created() { },
  watch: {
    height(val) {
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout();
      });
    },
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
    // 禁用的时间范围
    pickerOptions() {
      let that = this;
      return {
        disabledDate(time) {
          let maxDate = Date.parse(that.fendDate); //YYYY-MM-DD转为毫秒级时间戳
          let minDate = Date.parse(that.fstartDate); //
          return time.getTime() > maxDate || time.getTime() < minDate;
        },
      };
    },
  },
  mounted() {
    this.getTableHeight();
    this.getareatree();
    this.getclasstatusList();
  },
  methods: {
    // 检测日期范围是否有交叉
    checkDaterange(e, daterangeIndex) {
      const { Form } = this;
      // 时间范围是否有交叉，默认没有
      let flag = true;
      Form.pauseDateList.forEach((item, index) => {
        if (item.pauseDate && daterangeIndex !== index) {
          // 检测日期范围的开始时间和结束时间是否重复
          if (item.pauseDate[0] === e[0] || item.pauseDate[1] === e[1] || item.pauseDate[0] === e[1] || item.pauseDate[1] === e[0]) {
            flag = false;
          }
          // 本次日期的开始时间不能交叉
          if (moment(e[0]).isBefore(item.pauseDate[1]) && moment(e[0]).isAfter(item.pauseDate[0])) {
            flag = false;
          }
          // 本次日期的结束时间不能交叉
          if (moment(e[1]).isBefore(item.pauseDate[1]) && moment(e[1]).isAfter(item.pauseDate[0])) {
            flag = false;
          }
          // 检测本次日期范围不能包含其他日期范围
          if (moment(e[0]).isBefore(item.pauseDate[0]) && moment(e[1]).isAfter(item.pauseDate[1])) {
            flag = false;
          }
        }
      });
      if (!flag) {
        Form.pauseDateList[daterangeIndex].pauseDate = null;
        this.$message.error("日期范围不能交叉");
      }
    },
    // 添加暂停日期
    puseAddDate() {
      //   if (this.Form.pauseDateList.length > 0) {
      //     let lastDate = this.getNextDate(
      //       this.Form.pauseDateList[
      //         this.Form.pauseDateList.length - 1
      //       ].pauseDate.substring(0, 10),
      //       0
      //     );
      //     let maxDate = this.getNextDate(this.fendDate.substring(0, 10), 0);
      //     console.log(lastDate, maxDate, lastDate < maxDate);
      //     if (lastDate && lastDate < maxDate) {
      //       this.Form.pauseDateList.push({
      //         pauseDate: this.getNextDate(
      //           this.Form.pauseDateList[this.Form.pauseDateList.length - 1]
      //             .pauseDate
      //         ),
      //         pauseTimeList: [
      //           {
      //             pauseTime: [
      //               new Date(0, 0, 0, 0, 0, 0),
      //               new Date(0, 0, 0, 23, 59, 59),
      //             ],
      //           },
      //         ],
      //       });
      //     } else {
      //       this.Form.pauseDateList.push({
      //         pauseDate: "",
      //         pauseTimeList: [
      //           {
      //             pauseTime: [
      //               new Date(0, 0, 0, 0, 0, 0),
      //               new Date(0, 0, 0, 23, 59, 59),
      //             ],
      //           },
      //         ],
      //       });
      //     }
      //   } else {
      //     this.Form.pauseDateList.push({
      //       pauseDate: "",
      //       pauseTimeList: [
      //         {
      //           pauseTime: [
      //             new Date(0, 0, 0, 0, 0, 0),
      //             new Date(0, 0, 0, 23, 59, 59),
      //           ],
      //         },
      //       ],
      //     });
      //   }

      const { Form } = this;
      if (Form.pauseDateList.length > 0) {
        // 定义开始时间
        // 拿到上一个日期范围的结束日期
        const dateLength = Form.pauseDateList.length;
        let lastLastDate = "";
        if (Form.pauseDateList[dateLength - 1].pauseDate) {
          lastLastDate = Form.pauseDateList[dateLength - 1].pauseDate[1];
        }
        // 定义这次日期范围的开始和结束
        let dateStart = "";
        let dateEnd = "";
        // 如果有上次的日期范围的结束时间，赋值这次日期范围的开始日期和结束日期
        if (lastLastDate) {
          dateStart = moment(lastLastDate).add(1, "d").format("yyyy-MM-DD");
          dateEnd = dateStart;
        }
        // 推添加的数据
        Form.pauseDateList.push({
          pauseDate: dateStart ? [dateStart, dateEnd] : null,
          pauseTimeList: [
            {
              pauseTime: [
                new Date(0, 0, 0, 0, 0, 0),
                new Date(0, 0, 0, 23, 59, 59)
              ]
            }
          ]
        });
      } else {
        // 如果是添加的第一条日期
        Form.pauseDateList.push({
          pauseDate: null,
          pauseTimeList: [
            {
              pauseTime: [
                new Date(0, 0, 0, 0, 0, 0),
                new Date(0, 0, 0, 23, 59, 59)
              ]
            }
          ]
        });
      }
    },
    // 获取某天日期的下一天，默认为第二天 默认输出格式为yyyy-mm-dd
    // 默认1下一天   0当前天  -1前一天
    getNextDate(date, day = 1, format = "{y}-{m}-{d}") {
      if (date) {
        date = date.match(/\d+/g).join("-"); // 格式处理
        const nDate = new Date(date);
        nDate.setDate(nDate.getDate() + day);

        const formatObj = {
          y: nDate.getFullYear(),
          m: nDate.getMonth() + 1,
          d: nDate.getDate(),
        };
        return format.replace(/{([ymd])+}/g, (result, key) => {
          const value = formatObj[key];
          return value.toString().padStart(2, "0");
        });
      } else {
        console.log("date格式不正确");
      }
    },
    // 删除暂停日期
    puseDeleteDate(index) {
      this.Form.pauseDateList.splice(index, 1);
    },
    // 添加暂停时间段
    puseAddTime(index) {
      this.Form.pauseDateList[index].pauseTimeList.push({
        pauseTime: [new Date(0, 0, 0, 0, 0, 0), new Date(0, 0, 0, 23, 59, 59)],
      });
    },
    // 删除暂停时间段
    puseDeletetime(index, indexInner) {
      this.Form.pauseDateList[index].pauseTimeList.splice(indexInner, 1);
    },
    // 清除暂停列表
    clearPauseList() {
      this.Form.pauseDateList = [];
    },
    //暂停&恢复学习
    pauseStudy(row) {
      this.projectId = row.projectId;
      this.Form.isPause = row.isPause;
      this.fstartDate = row.startDate;
      this.fendDate = row.endDate;
      this.$post("/biz/project/pauseList", {
        projectId: row.projectId,
      })
        .then((res) => {
          this.dialogVisible = true;
          if (res.data.length > 0) {
            this.Form.pauseDateList = [];
            res.data.forEach((item, index) => {
              item.pauseStartDate = item.pauseStartDate.replaceAll("/", "-");
              item.pauseEndDate = item.pauseEndDate.replaceAll("/", "-");
              let data = {
                pauseDate: [item.pauseStartDate, item.pauseEndDate],
                pauseTimeList: [],
              };
              item.limitTimeList.forEach((itemInner, indexInner) => {
                let limitStart = this.resetTime(itemInner.limitStart);
                let limitEnd = this.resetTime(itemInner.limitEnd);
                let dataInner = {
                  pauseTime: [limitStart, limitEnd],
                };
                data.pauseTimeList.push(dataInner);
              });
              this.Form.pauseDateList.push(data);
            });
            this.Form.pauseDateList = pauseDateList;
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 重置时间
    resetTime(timeStr) {
      return new Date(
        0,
        0,
        0,
        Number(timeStr.substring(0, 2)),
        Number(timeStr.substring(3, 5)),
        Number(timeStr.substring(6, 9))
      );
    },
    //暂停学习确定
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let hasFalse = false; //判断暂停表单是否有异常
          let parmar = {
            projectId: this.projectId,
            isPause: this.Form.isPause,
          };
          // let pauseJustDateList = []; //用来储存所有日期校验重复
          let pauseDateList = []; //提交参数

          if (this.Form.pauseDateList.length > 0 && this.Form.isPause) {
            this.Form.pauseDateList.forEach((item) => {
              if (!item.pauseDate) {
                // 判断有没有没选择的日期范围
                if (!hasFalse) {
                  this.$message.error("请补全未选择的暂停日期范围！");
                  hasFalse = true;
                }
              } else {
                // pauseJustDateList.push(item.pauseDate);
                let data = {
                  // pauseDate: item.pauseDate,
                  pauseStartDate: item.pauseDate[0],
                  pauseEndDate: item.pauseDate[1],
                  pauseTimeList: [],
                }
                // 循环检测时间片段
                item.pauseTimeList.forEach((itemInner) => {
                  // 处理时间片段
                  let dataInner = {
                    limitStart: this.getNowTime(itemInner.pauseTime[0]),
                    limitEnd: this.getNowTime(itemInner.pauseTime[1]),
                  }
                  data.pauseTimeList.push(dataInner);
                });
                pauseDateList.push(data);
              }
            });
            //校验日期是否有重复的
            // if (pauseJustDateList.length > 1 && this.isRepeat(pauseJustDateList)) {
            //     this.$message.error("暂停日期不允许重复！");
            //     hasFalse = true;
            // }
            parmar.pauseDateList = pauseDateList;
          } else {
            parmar.pauseDateList = pauseDateList;
          }
          // return;
          if (!hasFalse) {
            this.$post("/biz/project/isPause", parmar)
              .then((ret) => {
                if (ret.status == "0") {
                  this.$message({
                    message: "操作成功",
                    type: "success",
                  });
                  this.getData(-1);
                  this.doClose();
                } else {
                  this.$message.error(ret.message);
                }
              })
              .catch((err) => {
                return;
              });
          }
        }
      });
    },
    //暂停学习取消
    doClose(Form) {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.Form = {
        isPause: "",
        pauseDateList: [],
      };
      // this.oldData = [];
    },
    // 导出暂停学习的记录
    exportRecords() {
      this.$post("/biz/project/exportProjectPauseList", {
        projectId: this.projectId,
      }).then((res) => {
        if (res.status == "0") {
          let list = [];
          list.push(res.data);
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.dialogVisible = false;
        } else {
          this.$message.error(re.message);
        }
      });
    },
    // 格式化日对象
    getNowTime(time) {
      var date = new Date(time);
      var sign2 = ":";
      // var year = date.getFullYear() // 年
      // var month = date.getMonth() + 1; // 月
      // var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      // var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      // var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      // if (month >= 1 && month <= 9) {
      //   month = "0" + month;
      // }
      // if (day >= 0 && day <= 9) {
      //   day = "0" + day;
      // }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      // year + "-" + month + "-" + day + " " +
      return hour + sign2 + minutes + sign2 + seconds;
    },
    // 校验数组是否有重复项
    isRepeat(arr) {
      var hash = {};
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        hash[arr[i]] = true;
      }
      return false;
    },

    // 批量选课件
    courseTableMoreBack(ret, projectId, questionBankEndDate) {
      let questionBankIds = [];
      // this.baseform.recommendName = ret.courseName
      ret.map((el) => {
        questionBankIds.push(el.questionBankId);
      });
      this.$post("/biz/project/configQuestionBank", {
        projectId,
        questionBankIds,
        questionBankEndDate
      }).then((res) => {
        if (res.status == "0") {
          this.$message({
            message: "配置成功",
            type: "success",
          });
          this.getData(-1)
        }
      });
    },
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    openStartClass(row) {
      this.$router.push({
        path: "/web/classListCom/startClassRecord",
        query: {
          projectId: row.projectId,
          projectSource: row.projectSource,
          auditState: row.auditState,
          areaId: row.areaId
        },
      });
    },
    getSwitch(value) {
      this.$post("/biz/project/updateClassHourCertificate", {
        projectId: value,
      }).then((res) => {
        if (res.status == "0") {
          this.getData(-1);
        }
      });
    },
    getStudentCertOperatePrint(projectId) {
      this.$post("/biz/project/studentCertOperatePrint/update", {
        projectId,
      }).then((res) => {
        if (res.status == "0") {
          this.getData(-1);
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        // ...this.selectDate,
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectName: this.selectDate.projectName,
        projectCode: this.selectDate.projectCode,
        compName: this.selectDate.compName,
        areaId: this.selectDate.areaId,
        isPause: this.selectDate.isPause,
        isSubsidy: this.selectDate.isSubsidy,
        projectStudyTimeState: this.selectDate.projectStudyTimeState,
      };
      if (this.selectDate.creationTime) {
        params.startDate = this.selectDate.creationTime[0];
        params.endDate = this.selectDate.creationTime[1];
      }
      if (this.selectDate.startTime) {
        params.startDateStart = this.selectDate.startTime[0];
        params.startDateEnd = this.selectDate.startTime[1];
      }
      if (this.selectDate.endTime) {
        params.endStartDate = this.selectDate.endTime[0];
        params.endEndDate = this.selectDate.endTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId; x
      }
      this.doFetch({
        url: "/biz/project/businessListPage",
        params,
        pageNum,
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getclasstatusList() {
      const classtatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classtatuslist) {
        list.push({
          value: key,
          label: classtatuslist[key],
        });
      }
      this.classtatusList = list;
    },
    //详情
    seeDetails(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("compId", row.compId);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("projectSource", row.projectSource);
      this.$router.push({
        path: "/web/classDetailY",
        query: {
          projectId: row.projectId,
          stu: "view",
          hasGovRollCall: JSON.stringify(row.hasGovRollCall) || "false",
          hasRollCall: JSON.stringify(row.hasRollCall) || "false",
          projectSource: row.projectSource,
        },
      });
    },
    // 学员信息
    studentInfo(projectId, projectSource, auditState, projectName) {
      sessionStorage.setItem("projectId", projectId);
      sessionStorage.setItem("projectSource", projectSource);
      sessionStorage.setItem("auditState", auditState);
      sessionStorage.setItem("projectName", projectName);

      this.$router.push({
        path: "/web/studentInfo",
        query: {
          projectId: projectId,
          projectSource: projectSource,
          auditState: auditState,
          projectName: projectName,
          refresh: true,
        },
      });
    },
    // 配置题库
    configurationQuestionBank({ projectId, endDate, questionBankEndDate }) {
      let time
      if (questionBankEndDate) {
        time = this.$moment(questionBankEndDate).format("YYYY-MM-DD")
      } else {
        time = endDate ? this.$moment(endDate).format("YYYY-MM-DD") : ''
      }
      this.$refs.courseQuestionBank.showPopUp(projectId, time);
      // this.$router.push({
      //   path:'/web/studentInfo',
      //   query:{
      //     projectId
      //   }
      // })
    },
    /* 档案失效 */
    fileInvalidation(projectId) {
      this.$confirm(
        "确认要清除全部的档案吗，重新生成档案将占用较长的时间",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.$post("/run/project/cache/obsolete", {
            projectId,
          }).then((res) => {
            this.$message({
              message: "档案失效成功",
              type: "success",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消档案失效",
          });
        });
    },
    /* 汇总档案失效 */
    SummaryFileInvalidation(projectId) {
      this.$post("/run/project/summary/cancellation", { projectId }).then(
        (ret) => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: "success",
            });
          }
        }
      );
    },
    // 班级参数
    Classparameters(projectId, projectStudyTimeState) {
      this.$router.push({
        path: "/web/classparameters",
        query: {
          projectId,
          stu: "edit",
          classListCtrY: "classListCtrY",
          projectStudyTimeState: projectStudyTimeState
        },
      });
    },
    // 班级抽查维护
    checkMaintenance(row) {
      console.log(row);
      this.$router.push({
        path: "/web/checkMaintenance",
        query: {
          projectId: row.projectId,
          projectCode: row.projectCode,
          projectName: row.projectName,
        },
      });
    },
    //清除未认证学员
    RemoveNonCertified(projectId) {
      this.$confirm("清除后学员信息将不可恢复，是否确认清除", "提示", {
        confirmButtonText: "确认清除",
        cancelButtonText: "取消清除",
        type: "warning",
      })
        .then(() => {
          this.$post("/run/project/clean/unverified/user", { projectId })
            .then((ret) => {
              if (ret.status == "0") {
                this.$message({
                  message: ret.message,
                  type: "success",
                });
              }
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {
          return;
        });
    },
    //自动评价
    AutomaticEvaluation(projectId) {
      this.$post("/biz/project/appraiseProject", { projectId }).then((ret) => {
        if (ret.status == "0") {
          this.$message({
            message: ret.message,
            type: "success",
          });
        }
      });
    },
    // 关闭弹框,清除已经生成的二维码
    closeCode() {
      this.$refs.qrcode.innerHTML = "";
    },
    // 展示二维码
    qrCodeIsGenerated(projectId, projectName, isRefresh = false) {
      this.innerVisible = true;
      this.innerTitle = projectName;
      this.innerProjectId = projectId;
      this.$post("/gsyfSubscribeCompAndProject/createSubscribeGsyfProjectQRCode", { projectId, isRefresh }).then(
        (ret) => {
          this.qrcode = ret.data;
        }
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight -
        (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}

.el-switch__core:after {
  background-color: #fff;
}

.dailyRemind-1 h2 {
  line-height: 3em;
}

.dailyRemind-1 {
  line-height: 2em;
}
</style>
